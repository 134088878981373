import React, { useEffect } from "react"
import { connect } from "react-redux"
import { Link, useStaticQuery } from "gatsby"
import { Container, Row, Col } from "reactstrap"

import shop_logo from "../images/shop/shop_logo.png"

import SEO from "../components/seo"
import Layout from "../components/layout"

import empty_star from "../images/reviews/star-empty.svg"
import half_star from "../images/reviews/star-half.svg"
import full_star from "../images/reviews/star-full.svg"

const Reviews = ({ dispatch, mobile }) => {
  const data = useStaticQuery(graphql`
    query {
      allReviewsJson {
        edges {
          node {
            stars
            review
            attribution
          }
        }
      }
    }
  `)
  let reviews = []
  let index = Object.keys(data)[0]
  let edges = data[index].edges
  edges.forEach(item => {
    reviews.push(item.node)
  })
  let display = reviews.map((i, idx) => {
    console.log(i.stars)
    let stars = ""
    if (i.stars === "0") {
      stars = (
        <div className="star-container">
          <img src={empty_star} alt="" />
          <img src={empty_star} alt="" />
          <img src={empty_star} alt="" />
          <img src={empty_star} alt="" />
          <img src={empty_star} alt="" />
        </div>
      )
    }
    if (i.stars === "0.5") {
      stars = (
        <div className="star-container">
          <img src={half_star} alt="" />
          <img src={empty_star} alt="" />
          <img src={empty_star} alt="" />
          <img src={empty_star} alt="" />
          <img src={empty_star} alt="" />
        </div>
      )
    }
    if (i.stars === "1") {
      stars = (
        <div className="star-container">
          <img src={full_star} alt="" />
          <img src={empty_star} alt="" />
          <img src={empty_star} alt="" />
          <img src={empty_star} alt="" />
          <img src={empty_star} alt="" />
        </div>
      )
    }
    if (i.stars === "1.5") {
      stars = (
        <div className="star-container">
          <img src={full_star} alt="" />
          <img src={half_star} alt="" />
          <img src={empty_star} alt="" />
          <img src={empty_star} alt="" />
          <img src={empty_star} alt="" />
        </div>
      )
    }
    if (i.stars === "2") {
      stars = (
        <div className="star-container">
          <img src={full_star} alt="" />
          <img src={full_star} alt="" />
          <img src={empty_star} alt="" />
          <img src={empty_star} alt="" />
          <img src={empty_star} alt="" />
        </div>
      )
    }
    if (i.stars === "2.5") {
      stars = (
        <div className="star-container">
          <img src={full_star} alt="" />
          <img src={full_star} alt="" />
          <img src={half_star} alt="" />
          <img src={empty_star} alt="" />
          <img src={empty_star} alt="" />
        </div>
      )
    }
    if (i.stars === "3") {
      stars = (
        <div className="star-container">
          <img src={full_star} alt="" />
          <img src={full_star} alt="" />
          <img src={full_star} alt="" />
          <img src={empty_star} alt="" />
          <img src={empty_star} alt="" />
        </div>
      )
    }
    if (i.stars === "3.5") {
      stars = (
        <div className="star-container">
          <img src={full_star} alt="" />
          <img src={full_star} alt="" />
          <img src={full_star} alt="" />
          <img src={half_star} alt="" />
          <img src={empty_star} alt="" />
        </div>
      )
    }
    if (i.stars === "4") {
      stars = (
        <div className="star-container">
          <img src={full_star} alt="" />
          <img src={full_star} alt="" />
          <img src={full_star} alt="" />
          <img src={full_star} alt="" />
          <img src={empty_star} alt="" />
        </div>
      )
    }
    if (i.stars === "4.5") {
      stars = (
        <div className="star-container">
          <img src={full_star} alt="" />
          <img src={full_star} alt="" />
          <img src={full_star} alt="" />
          <img src={full_star} alt="" />
          <img src={half_star} alt="" />
        </div>
      )
    }
    if (i.stars === "5") {
      stars = (
        <div className="star-container">
          <img src={full_star} alt="" />
          <img src={full_star} alt="" />
          <img src={full_star} alt="" />
          <img src={full_star} alt="" />
          <img src={full_star} alt="" />
        </div>
      )
    }
    return (
      <div key={idx} className="reviews-container">
        <div className="reviews">{i.review}</div>
        <div className="reviews-attribution">{i.attribution}</div>
        {stars}
      </div>
    )
  })
  return (
    <Layout>
      <SEO title="Reviews" />
      <Container fluid className="reviews-body-container">
        <Row className="d-flex justify-content-center">
          <Col>
            <div className="reviews-title">Customer Love</div>
          </Col>
        </Row>
        <Row className="d-flex justify-content-center">
          <Col>{display}</Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default connect(
  state => ({
    mobile: state.global.mobile,
  }),
  null
)(Reviews)
